<template>
  <div class="relative md:flex md:justify-center">
    <a-form-model ref="ruleForm" :model="address" :rules="rules" class="h-inherit">
      <article class="h-inherit pt-14 md:pt-4 md:w-1200">
        <div class="bg-gray-100 md:bg-white pt-2 md:pt-0 h-min-full rounded-t-3xl" v-if="initDone">
          <div class="container-wrapper md:px-0">
            <h1 class="text-xl md:text-3xl text-brown my-2 md:mb-8">結帳</h1>
            <!--結帳-->
            <section class="bg-white p-4 md:p-0 flex flex-col md:flex-row md:mb-12">
              <!--描述-->
              <img class=" mr-8 rounded-lg hidden md:block w-72 h-72 object-fill" :src="form.product.image" :onerror="imgNotFound" />
              <div class="flex-1 flex flex-col">
                <article class="flex flex-row mb-2 items-center">
                  <img class="cart-item-img mr-2 block md:hidden" :src="form.product.image" :onerror="imgNotFound" />
                  <div>
                    <p class="text-sm opacity-60 text-right mb-1 md:text-left">{{ form.time }}加入</p>
                    <p class="text-brown font-medium md:text-left md:text-2xl md:mb-0">{{ form.product.name }}</p>
                  </div>
                </article>
                <article>
                  <ul>
                    <li v-for="(pack, packIdx) in form.packs" :key="packIdx">
                      <div v-if="pack.quantity >= 1" class="py-1 px-2 mb-4 md:p-4 bg-gray-50 rounded-md flex flex-col justify-between items-center md:text-lg">
                        <section class="flex flex-row justify-between w-full">
                          <!--名稱 / 規格-->
                          <div class="flex justify-between items-baseline text-brown md:mr-12 price-min-width">
                            <span>{{ pack.name }}</span>
                            <span class="ml-4 text-gray-400" v-if="pack.spec != 0"> {{ getSpecName(pack.spec) }}</span>
                          </div>
                          <!--價格 / 數量-->
                          <div class="md:flex items-baseline md:justify-between">
                            <span class="text-pink-salmon text-right">{{ pack.price.grouped }}</span>
                            <span class="text-gray-400  w-10 text-right "> x {{ form.packs[packIdx].quantity }}</span>
                          </div>
                        </section>
                        <section class="relative w-full">
                          <a-textarea class="textarea-basic my-2" placeholder="備註" :auto-size="{ minRows: 2, maxRows: 5 }" allow-clear v-model="form.packs[packIdx].comment" />
                          <br />
                        </section>
                      </div>
                    </li>
                  </ul>
                </article>
                <!-- <article>
                  <a-checkbox @change="onInvoiceEmailChecked" default-checked>發票寄送至收件人電子郵件</a-checkbox>
                  <a-form-model-item class="mb-0" prop="invoice_email" ref="invoice_email" v-if="!isInvoiceEmailChecked">
                    <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="address.invoice_email" placeholder="發票寄送電子郵件"></a-input>
                  </a-form-model-item>
                </article> -->
                <!-- Footer 總價格 -->
                <div class="hidden md:flex justify-end md:p-4 text-xl">
                  <p class="mb-0 text-black">
                    總金額
                    <span class="text-pink-salmon font-bold">{{ form.price.total }}</span>
                  </p>
                  <p class="ml-12 mb-0 text-gray-400">
                    均價
                    <span class="text-pink-salmon font-bold">{{ Math.floor(form.price.average) }}</span>
                  </p>
                </div>
              </div>
            </section>

            <!--收件方式-->
            <section class="bg-white mt-3 md:mt-0">
              <div class="container-wrapper">
                <header class="flex flex-row justify items-center border-b mb-1">
                  <img class="w-5 h-5 mr-2 md:w-8 md:h-8 md:mr-4 " src="../../assets/img/icon_truck.svg" />
                  <h1 class="text-xl md:text-2xl text-brown my-2">收件方式</h1>
                </header>
                <article>
                  <a-radio-group class="w-full" v-model="shipValue" @change="changeShipClassify">
                    <div v-for="(ship, shipIdx) in mockShipData" :key="shipIdx">
                      <div class="flex justify-between items-center py-3 c-radio-group">
                        <a-radio :value="ship.value">
                          {{ ship.name }}
                        </a-radio>
                        <span> +運費 <span class="text-pink-salmon inline-block w-10 md:w-20 text-right">0</span> </span>
                      </div>
                      <!--宅配-->
                      <template v-if="(shipValue == 'ship-value-a') & (shipIdx == 0)">
                        <!--與HomeAddress.vue相同-->

                        <div class="mb-4 hidden md:ml-6 md:w-80 md:block">
                          <button class="btn-basic btn-block p-1 mt-4" @click="openModalHome()">選擇地址</button>
                          <!-- <span class="md:text-sm text-pink-salmon">無設置常用地址</span> -->
                        </div>
                        <div class="mb-4 md:w-1/2 md:inline-block md:px-6">
                          <label class="block text-sm font-bold mb-1 md:text-lg">收件人姓名<span class="ml-1 text-pink-salmon">*</span></label>
                          <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="address.name" placeholder="請輸入姓名"></a-input>
                        </div>
                        <div class="mb-4 md:w-1/2 md:inline-block md:px-6">
                          <label class="block text-sm font-bold mb-1 md:text-lg">收件人電話<span class="ml-1 text-pink-salmon">*</span></label>
                          <a-form-model-item class="mb-0" prop="mobile" ref="mobile">
                            <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="address.mobile" placeholder="請輸入電話"></a-input>
                          </a-form-model-item>
                        </div>
                        <div class="md:w-1/2 md:px-6">
                          <label class="flex justify-between items-center text-sm font-bold mb-1 md:text-lg">
                            <div><span>收件人郵件</span><span class="ml-1 text-pink-salmon">*</span></div>
                            <span class="opacity-60 md:text-sm">電子發票將郵寄至此信箱</span>
                          </label>
                          <a-form-model-item class="mb-0" prop="email" ref="email">
                            <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="address.email" placeholder="請輸入收件人郵件"></a-input>
                          </a-form-model-item>
                        </div>
                        <div class="mb-4 md:w-1/2 md:inline-block md:px-6">
                          <label class="block text-sm font-bold mb-1 md:text-lg">縣市<span class="ml-1 text-pink-salmon">*</span></label>
                          <twzipcode-county class="c-select" id="twzipcode__county" v-model="address.county"></twzipcode-county>
                        </div>
                        <div class="mb-4 md:w-1/2 md:inline-block md:px-6">
                          <label class="block text-sm font-bold mb-1 md:text-lg">鄉鎮市區<span class="ml-1 text-pink-salmon">*</span></label>
                          <twzipcode-zipcode
                            value-template=":city"
                            text-template=":city"
                            id="twzipcode__zipcode"
                            class="c-select"
                            v-model="address.district"
                            :filter-by-county="address.county"
                          ></twzipcode-zipcode>
                        </div>
                        <div class="mb-4 md:px-6">
                          <label class="block text-sm font-bold mb-1 md:text-lg ">收件地址<span class="ml-1 text-pink-salmon">*</span></label>
                          <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="address.detail" placeholder="請輸入您的地址"></a-input>
                        </div>
                        <div class="mb-4 md:px-6">
                          <label class="block text-sm font-bold mb-1 md:text-lg ">
                            <!-- <span class="ml-1 text-pink-salmon">*</span> -->
                            <a-checkbox class="block text-sm font-bold mb-1 md:text-lg" @change="handleChangeShouldUseCoco">使用COCO幣折抵
                              <span class="pl-5">COCO幣餘額:<span class="text-pink-salmon">${{showCoco | currency}}</span></span>
                            </a-checkbox>
                          </label>
                          <a-input class="rounded-2xl bg-gray-100 ant-input" :class="{'hidden': !shouldUseCoco}" placeholder="請輸入COCO幣折抵金額" @change="handleChangeUsedCoco" v-model="usedCoco" autocomplete="nope"></a-input>
                        </div>
                        <div class="mb-4 md:w-1/2 md:inline-block md:px-6" v-if="showTotal > 0">
                          <label class="block text-sm font-bold mb-1 md:text-lg">付款方式<span class="ml-1 text-pink-salmon">*</span></label>
                          <a-radio-group name="paymentRadioGroup" v-model="paymentType">
                            <a-radio :value="payment.paymentTypeId" v-for="(payment, index) in form.payments" :key="index">
                              {{ payment.paymentTypeCode == 'ECPay.WebATM' ? 'ATM付款' : payment.paymentTypeCode == 'ECPay.CreditCard' ? '信用卡付款' : payment.paymentTypeCode }}
                            </a-radio>
                          </a-radio-group>
                        </div>
                        <div class="mb-4 md:px-6" v-if="showTotal > 0">
                          <label class="block text-sm font-bold mb-1 md:text-lg">發票資訊<span class="ml-1 text-pink-salmon">*</span></label>
                          <a-radio-group name="invoiceRadioGroup" v-model="invoiceType">
                            <a-radio :value="0">個人雲端發票</a-radio>
                            <a-radio :value="2">公司戶雲端發票(三聯式)</a-radio>
                          </a-radio-group>
                        </div>
                        <div class="mt-4" v-if="invoiceType == 2">
                            <div class="md:w-1/2 md:inline-block md:px-6">
                              <label class="flex justify-between items-center text-sm font-bold mb-1 md:text-lg">
                                <div><span>買受人/抬頭</span><span class="ml-1 text-pink-salmon">*</span></div>
                              </label>
                              <a-form-model-item class="mb-0" prop="corpInvoiceTitle" ref="corpInvoiceTitle">
                                <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="address.corpInvoiceTitle" placeholder="請輸入買受人/抬頭"></a-input>
                              </a-form-model-item>
                            </div>
                            <div class="md:w-1/2 md:inline-block md:px-6">
                              <label class="flex justify-between items-center text-sm font-bold mb-1 md:text-lg">
                                <div><span>統一編號</span><span class="ml-1 text-pink-salmon">*</span></div>
                              </label>
                              <a-form-model-item class="mb-0" prop="corpInvoiceVat" ref="corpInvoiceVat">
                                <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="address.corpInvoiceVat" placeholder="請輸入統一編號"></a-input>
                              </a-form-model-item>
                            </div>
                            <div class="md:w-1/2 md:px-6">
                              <label class="flex justify-between items-center text-sm font-bold mb-1 md:text-lg">
                                <div><span>發票電子郵件</span><span class="ml-1 text-pink-salmon">*</span></div>
                              </label>
                              <a-form-model-item class="mb-0" prop="corpInvoiceEmail" ref="corpInvoiceEmail">
                                <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="address.corpInvoiceEmail" placeholder="請輸入發票電子郵件"></a-input>
                              </a-form-model-item>
                            </div>
                            <div class="mb-4 md:w-1/2 md:inline-block md:px-6">
                              <label class="block text-sm font-bold mb-1 md:text-lg">縣市<span class="ml-1 text-pink-salmon">*</span></label>
                              <twzipcode-county class="c-select" id="twzipcode__county" v-model="address.corpInvoiceCounty"></twzipcode-county>
                            </div>
                            <div class="mb-4 md:w-1/2 md:inline-block md:px-6">
                              <label class="block text-sm font-bold mb-1 md:text-lg">鄉鎮市區<span class="ml-1 text-pink-salmon">*</span></label>
                              <twzipcode-zipcode
                                value-template=":city"
                                text-template=":city"
                                id="twzipcode__zipcode"
                                class="c-select"
                                v-model="address.corpInvoiceDistrict"
                                :filter-by-county="address.corpInvoiceCounty"
                              ></twzipcode-zipcode>
                            </div>
                            <div class="md:px-6">
                              <label class="flex justify-between items-center text-sm font-bold mb-1 md:text-lg">
                                <div><span>發票地址</span><span class="ml-1 text-pink-salmon">*</span></div>
                              </label>
                              <a-form-model-item class="mb-0" prop="corpInvoiceAddress" ref="corpInvoiceAddress">
                                <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="address.corpInvoiceAddress" placeholder="請輸入發票地址"></a-input>
                              </a-form-model-item>
                            </div>
                          </div>
                        

                        <div class="mb-4 md:hidden">
                          <button class="btn-basic btn-block p-1 mt-4" @click="openModalHome()">選擇地址</button>
                        </div>
                      </template>

                      <!--7-11-->
                      <template v-if="(shipValue == 'ship-value-b') & (shipIdx == 1)">
                        <div class="pl-8" v-if="mockShopAddress.isSelected">
                          <h6 class="text-bold">{{ mockShopAddress.name }}</h6>
                          <span class="opacity-60">{{ mockShopAddress.address }}</span>
                        </div>
                        <button class="btn-basic btn-block p-1 mt-4" @click="openModalShop()">選擇門市</button>
                      </template>
                    </div>
                  </a-radio-group>
                </article>
              </div>
            </section>

            <div class="h-36"></div>
          </div>

          <!-- footer -->
          <footer class="fixed w-full p-3 bottom-0 bg-white custom-shadow-top md:absolute md:left-0 md:bottom:0">
            <div class="md:max-w-1200 md:mx-auto md:flex md:items-end md:flex-col">
              <div class="flex flex-row justify-between border-b mx-3 pb-2 md:justify-end md:w-1/3">
                <span class="text-brown text-xl">總金額</span>
                <span class="text-pink-salmon font-semibold text-xl md:ml-4">{{ showTotal }}</span>
              </div>
              <div class="btn-bottom-groups mt-4 md:w-1/3">
                <button class="btn-forms btn-light" @click="handleCancel()">取消</button>
                <button class="btn-forms" :disabled="!isRequirePassed" @click="handleOrder">結帳</button>
              </div>
            </div>
          </footer>
        </div>

        <!-- -------------------- modal -------------------- START -->
        <!--modal 選擇地址-->
        <a-modal transitionName="fade" :class="isMobile ? 'ant-modal-bottom' : ''" title="選擇地址" :closable="false" v-model="modals.home.isShow">
          <header class="flex flex-row justify-between items-center border-b mb-1 md:border-none">
            <div class="flex flex-row items-center">
              <img src="../../assets/img/logo/icon_house.svg" class="w-5 h-5 mr-2" />
              <h1 class="font-bold text-brown my-2">宅配</h1>
            </div>
          </header>

          <!--選擇宅配地址-->
          <template v-if="initDone">
            <article class="border-b mb-1 md:border-none">
              <div class="flex flex-row items-center py-2" v-for="home in addresses" :key="home.id">
                <div>
                  <a-radio-group name="radioGroupHomeAddress" v-model="modals.home.radioValue">
                    <a-radio class="radio-address flex items-center" :value="home.id">
                      <div class="flex flex-row w-100">
                        <span class="mr-4">{{ home.name }}</span>
                        <span class="mr-4">{{ home.mobile }}</span>
                      </div>
                      <div class="flex flex-row w-100">
                        <span class="opacity-60"> {{ home.email }}</span>
                      </div>
                      <div class="flex flex-row">
                        <span class="opacity-60 home-address">{{ home.county }}{{ home.district }}{{ home.detail }}</span>
                      </div>
                    </a-radio>
                  </a-radio-group>
                </div>
              </div>
            </article>
          </template>

          <template slot="footer">
            <div class="btn-bottom-groups">
              <button class="btn-forms btn-light" @click="modalHomeCancel()">取消</button>
              <button class="btn-forms" @click="modalHomeOK()">確定</button>
            </div>
          </template>
        </a-modal>

        <!--modal 選擇門市-->
        <a-modal transitionName="fade" :class="isMobile ? 'ant-modal-bottom' : ''" title="選擇門市" :closable="false" v-model="modals.shop.isShow">
          <header class="flex flex-row justify-between items-center border-b mb-1">
            <div class="flex flex-row items-center">
              <img src="../../assets/img/logo/icon_711.svg" class="w-5 h-5 mr-2" />
              <h1 class="font-bold text-brown my-2">7-11超商</h1>
            </div>
            <div>
              <button class="btn-basic btn-block py-0 px-3">+ 新增門市</button>
            </div>
          </header>

          <!--選擇門市地址-->
          <template v-if="initDone">
            <article>
              <div class="flex flex-row items-center py-2" v-for="(item, idx) in mockShopAddress.data" :key="idx">
                <a-radio-group name="radioGroup" v-model="shopValue" @change="changeShopAddress">
                  <a-radio class="radio-address" :value="item.name">
                    <div class="inline-block">
                      <h6 class="text-bold">{{ item.name }}</h6>
                      <div class="opacity-60">{{ item.address }}</div>
                    </div>
                  </a-radio>
                </a-radio-group>
              </div>
            </article>
          </template>

          <template slot="footer">
            <div class="btn-bottom-groups">
              <button class="btn-forms btn-light" @click="modalShopCancel">取消</button>
              <button class="btn-forms" @click="modalShopOK">確定</button>
            </div>
          </template>
        </a-modal>

        <!-- -------------------- modal -------------------- END -->

        <!--結帳畫面-->
        <div class="fixed w-full h-screen" v-if="showHtml" ref="refPaymentHtml" v-html="paymentHtml"></div>
      </article>
    </a-form-model>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex';

//假資料 商品
import { mockProductData } from '@/data/mockData'
const mockData = mockProductData

//假資料 宅配
const mockShipData = [
  { name: '宅配', price: 60, value: 'ship-value-a' }
  // { name: '7-11', price: 60, value: 'ship-value-b' }
]
//假資料 7-11 data列表與選擇好的地址,
const mockShopAddress = {
  name: '市大門市',
  address: '台北市松山區市民大道路四段105號',
  isSelected: false, //7-11有沒有選擇
  data: [
    {
      name: '市大門市2',
      address: '台北市松山區市民大道路四段105號',
      isSelected: false //7-11有沒有選擇
    },
    {
      name: '市大門市3',
      address: '台北市松山區市民大道路四段105號',
      isSelected: false //7-11有沒有選擇
    }
  ]
}

const mockHomeAddress = {
  data: [
    {
      address: '台北市松山區市民大道路四段1號'
    },
    {
      address: '台北市松山區市民大道路四段2號'
    }
  ]
}

//假資料 收件人
import { mockReceiverData } from '@/data/mockData'

import { mapActions, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import Zipcode from '@/components/zipcode/twzipcode-vue/zipcodes.vue'
import County from '@/components/zipcode/twzipcode-vue/counties.vue'
import { LogEnums } from '@/utils/enums'
import { regExpEmail, regCoco, regExpMobile } from '@/utils/regExp.js'
import message from 'ant-design-vue/es/message'
import { checkout } from '@/utils/ga.js'


export default {
  name: 'checkout',
  components: {
    TwzipcodeZipcode: Zipcode,
    TwzipcodeCounty: County
  },
  data() {
    return {
      mockData, //商品
      mockReceiverData, //收件人
      mockShipData, //宅配, 7-11, 全家
      mockHomeAddress, //宅配地址
      mockShopAddress, //7-11地址
      isInvoiceEmailChecked: true,
      modals: {
        shop: { isShow: false },
        home: { isShow: false, radioValue: 0 },
        data: [
          {
            sevenEleven: [this.mockShopAddress, this.mockShopAddress],
            family: [this.mockShopAddress, this.mockShopAddress]
          }
        ]
      },
      currentShopAddress: '', // 便利商店選擇中的地址value
      CART_ID: '',
      imgNotFound: 'this.src="' + require('../../assets/img/status/not_found_img_72x72.jpg') + '"',
      currentHomeId: 0,
      currentHomeIndex: 0,
      shipValue: '',
      shopValue: '',
      initDone: false,
      total: 0,
      form: '',
      calc: '',
      address: {
        type: '0',
        name: '',
        email: '',
        mobile: '',
        county: '臺北市',
        district: '',
        detail: '',
        invoice_email: '',
        corpInvoiceTitle: '',
        corpInvoiceVat: '',
        corpInvoiceEmail: '',
        corpInvoiceCounty: '臺北市',
        corpInvoiceDistrict: '',
        corpInvoiceAddress: ''
      },
      formAddress: {
        // invoice_email: '', //發票地址
        // receiver_name: '', //訂單收件人姓名
        // receiver_mobile: '', //訂單收件人手機
        // receiver_email: '', //訂單收件人郵件
        // delivery_type: '', //0: 宅配
        // receiver_code: '', //物流超商代碼
        // receiver_address: '', //物流配送地址,
        // payment_type: ''
      },
      paymentType: -1,
      invoiceType: 0,
      // corpInvoice: {
      //   title: '',
      //   number: '',
      //   email: '',
      //   county: '臺北市',
      //   district: '',
      //   address: ''
      // },
      rules: {
        email: { required: true, validator: (rule, value, callback) => this.validatorEmail(value, callback, 'Email格式不正確'), trigger: 'change' },
        // invoice_email: { required: false, validator: (rule, value, callback) => this.validatorEmail2(value, callback, 'Email格式不正確或是不可空白'), trigger: 'change' },
        mobile: { required: true, validator: (rule, value, callback) => this.validatorVerify(value, callback, '手機號碼輸入格式為 0987654321'), trigger: 'change' },
        corpInvoiceTitle: { require: false, validator: (rule, value, callback) => this.validatorCorpInvoiceTitle(value, callback, '買受人/抬頭不可為空'), trigger: 'change' },
        corpInvoiceVat: { require: false, validator: (rule, value, callback) => this.validatorCorpInvoiceVat(value, callback, '統一編號不可為空'), trigger: 'change' },
        corpInvoiceEmail: { require: false, validator: (rule, value, callback) => this.validatorCorpInvoiceEmail(value, callback, '發票電子郵件不可為空'), trigger: 'change' },
        corpInvoiceAddress: { require: false, validator: (rule, value, callback) => this.validatorCorpInvoiceAddress(value, callback, '發票地址不可為空'), trigger: 'change' }
      },
      showHtml: '', //按結帳後會顯示html畫面
      isEmailCorrect: false,
      isEmailCorrect2: true,
      isMobileCorrect: false,
      isCorpInvoiceTitleCorrect: false,
      isCorpInvoiceVatCorrect: false,
      isCorpInvoiceEmailCorrect: false,
      isCorpInvoiceAddressCorrect: false,
      shouldUseCoco: false, //是否使用COCO幣
      cocoCash: 0, //COCO幣餘額
      usedCoco: 0, //使用COCO幣
    }
  },
  methods: {
    ...mapActions(['getAddressesData', 'checkoutPayment', 'postLog', 'renewCart', 'getUserCoco']),
    // onInvoiceEmailChecked(e) {
    //   // this.address.invoice_email = ''
    //   // this.formAddress.invoice_email = ''
    //   // this.isEmailCorrect2 = true
    //   // this.isInvoiceEmailChecked = e.target.checked
    // },
    initData() {
      this.CART_ID = this.$route.params.form.id
      this.form = this.$route.params.form
      this.total = this.$route.params.total
      this.itemIdx = this.$route.params.itemIdx
      this.paymentType = this.$route.params.form.payments[0].paymentTypeId
    },
    getSpecName(id) {
      //從規格id找規格名稱
      let obj = JSON.parse(JSON.stringify(this.form.specs))
      let result = 0
      obj.forEach(spec => {
        if (spec.id == id) {
          result = spec.name
        }
      })
      return result
    },
    validatorEmail(value, callback, errorText) {
      console.log(value)
      const reg = regExpEmail
      if (!reg.test(value)) {
        callback(errorText)
        this.isEmailCorrect = false
      } else if (reg.test(value)) this.isEmailCorrect = true
      callback()
    },
    validatorEmail2(value, callback, errorText) {
      console.log(value)
      const reg = regExpEmail
      if (!reg.test(value)) {
        this.isEmailCorrect2 = false
        callback(errorText)
      } else if (reg.test(value)) this.isEmailCorrect2 = true
      callback()
    },
    validatorVerify(value, callback, errorText) {
      console.log(value)
      const reg = regExpMobile
      if (!reg.test(value)) {
        this.isMobileCorrect = false
        callback(errorText)
      } else if (reg.test(value)) this.isMobileCorrect = true
      callback()
    },
    validatorCorpInvoiceTitle(value, callback, errorText) {
      console.log(value)
      if (this.invoiceType == 2) {
        if(value == '') {
          this.isCorpInvoiceTitleCorrect = false
          callback(errorText)
        } else {
          this.isCorpInvoiceTitleCorrect = true
        }
      } else {
        this.isCorpInvoiceTitleCorrect = true
      }
      callback()
    },
    validatorCorpInvoiceVat(value, callback, errorText) {
      console.log(value)
      if (this.invoiceType == 2) {
        if(value == '') {
          this.isCorpInvoiceVatCorrect = false
          callback(errorText)
        } else {
          this.isCorpInvoiceVatCorrect = true
        }
      } else {
        this.isCorpInvoiceVatCorrect = true
      }
      callback()
    },
    validatorCorpInvoiceEmail(value, callback, errorText) {
      console.log(value)
      if (this.invoiceType == 2) {
        if(value == '') {
          this.isCorpInvoiceEmailCorrect = false
          callback(errorText)
        } else {
          this.isCorpInvoiceEmailCorrect = true
        }
      } else {
        this.isCorpInvoiceEmailCorrect = true
      }
      callback()
    },
    validatorCorpInvoiceAddress(value, callback, errorText) {
      console.log(value)
      if (this.invoiceType == 2) {
        if(value == '') {
          this.isCorpInvoiceAddressCorrect = false
          callback(errorText)
        } else {
          this.isCorpInvoiceAddressCorrect = true
        }
      } else {
        this.isCorpInvoiceAddressCorrect = true
      }
      callback()
    },
    renewHtml() {
      // refPaymentHtml
      // let routeData = this.$router.resolve({ path: '/recharge', query: { html: html }});
      // window.open(routeData.href, '_blank');
      // window.open(routeData.href, '_blank');
      // document.write(html)
      // const div = document.createElement('div')
      // div.innerHTML = html
      // document.body.appendChild(div)
      // document.forms[1].submit();
    },
    resetAddressFromId(id) {
      let HOME_INDEX
      this.addresses.forEach((e, i) => {
        if (e.id == id) {
          HOME_INDEX = i
        }
      })
      console.log(HOME_INDEX)
      this.address.type = this.addresses[HOME_INDEX].type
      this.address.name = this.addresses[HOME_INDEX].name
      this.address.email = this.addresses[HOME_INDEX].email
      this.address.mobile = this.addresses[HOME_INDEX].mobile
      this.address.county = this.addresses[HOME_INDEX].county
      setTimeout(() => {
        this.address.district = this.addresses[HOME_INDEX].district
      }, 0)
      this.address.detail = this.addresses[HOME_INDEX].detail

      this.$nextTick(function() {
        this.$refs[`email`][0].validate()
      })
    },

    changeShipClassify(e) {
      console.log('changeShipClassify')
      console.log('收件方式', e.target.value)
    },
    changeShopAddress(e) {
      console.log('changeShopAddress')
      console.log('選擇門市', e.target.value)
    },
    setModalHide(item) {
      this.modals[item].isShow = false
    },
    setModalShow(item) {
      this.modals[item].isShow = true
    },
    openModalHome() {
      if(this.addresses.length <= 0) {
        message.error('無設置常用地址')
        return
      }

      this.setModalShow('home')
    },
    modalHomeOK() {
      this.setModalHide('home')
      this.currentHomeId = this.modals.home.radioValue
    },
    modalHomeCancel() {
      this.setModalHide('home')
    },
    openModalShop() {
      this.setModalShow('shop')
    },
    modalShopOK() {
      this.setModalHide('shop')
      //選擇了門市地址
      mockShopAddress.isSelected = true
    },
    modalShopCancel() {
      this.setModalHide('shop')
    },
    handleCancel() {
      this.$router.push('/cart')
    },
    initFormAddress() {
      console.log('initFormAddress')
      this.formAddress.receiver_name = this.address.name
      this.formAddress.receiver_mobile = this.address.mobile
      this.formAddress.receiver_email = this.address.email
      this.formAddress.invoice_email = this.address.email
      this.formAddress.delivery_type = this.address.type
      // this.formAddress.receiver_code = this.address.code
      this.formAddress.receiver_address = this.address.county + this.address.district + this.address.detail
      this.formAddress.payment_type = this.paymentType
      this.formAddress.coco_cash = this.usedCoco
      this.formAddress.total = this.showTotal
      this.formAddress.invoice_type = this.invoiceType

      //公司三聯式發票
      if (this.invoiceType == 2) {
        this.formAddress.invoice_title = this.address.corpInvoiceTitle
        this.formAddress.invoice_vat = this.address.corpInvoiceVat
        this.formAddress.invoice_addr = this.address.corpInvoiceCounty + this.address.corpInvoiceDistrict + this.address.corpInvoiceAddress
        this.formAddress.invoice_email = this.address.corpInvoiceEmail
      }
    },
    handleGetAddressesData() {
      // * api 讀取地址
      let params = new FormData()
      params.append('type', this.formAddress.delivery_type)
      this.getAddressesData(params)
    },
    async handleRenewCart() {
      // * api 更新購物車
      const formData = new FormData()
      const id = String(this.CART_ID)
      const data = JSON.stringify(this.form)
      await formData.append('id', Number(id))
      await formData.append('data', data)
      await this.renewCart({ id, formData })
    },
    async handleOrder() {
      // 驗證
      console.log(this.$refs.ruleForm)
      this.$refs.ruleForm.validate(pass => {
        console.log(pass)
      })
      
      //* api 更新備註到訂單
      await this.handleRenewCart()

      //* api 結帳到金流網頁
      await this.initFormAddress() //將HOME地址放進formAddress
      const formData = await new FormData()
      const id = await String(this.CART_ID)
      console.log(id)
      for (var key in this.formAddress) {
        await formData.append(key, this.formAddress[key])
      }

      // Display the key/value pairs
      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }

      await this.checkoutPayment({ id, formData })
    },
    handleGaCheckoutData() {
      const items = []
      this.items.forEach(el => {
        const packs = el.packs.map(e => {
          return {
            name: `${el.product.name} - ${e.name}`,
            id: e.id,
            price: e.price.total,
            quantity: e.quantity
          }
        })
        items.push(...packs)
      })
      return items
    },
    handleChangeShouldUseCoco() {
      this.shouldUseCoco = !this.shouldUseCoco
      this.usedCoco = 0
    },
    handleChangeUsedCoco() {
      const reg = regCoco
      if(this.usedCoco == '') this.usedCoco = 0
      if(this.usedCoco >= this.userCoco) this.usedCoco = this.userCoco
      if(this.usedCoco >= this.total) this.usedCoco = this.total
      if(this.usedCoco.toString().length > 1) {
        if(this.usedCoco.toString()[0] == '0') {
          this.usedCoco = this.usedCoco.toString().replace('0', '')
        }
      }
      if(!reg.test(this.usedCoco)) this.usedCoco = 0
    }
  },
  computed: {
    ...mapGetters(['addresses', 'isMobile', 'paymentHtml', 'items', 'userCoco']),
    isRequirePassed() {
      let isPass = false
      if (
        !isEmpty(this.address.name) &
        !isEmpty(this.address.mobile) &
        !isEmpty(this.address.county) &
        !isEmpty(this.address.district) &
        !isEmpty(this.address.detail) &
        this.isEmailCorrect &
        this.isEmailCorrect2 &
        this.isMobileCorrect
      ) {
        isPass = true
      }
      return isPass
    },
    showCoco() {
      return this.userCoco - this.usedCoco 
    },
    showTotal() {
      return this.total - this.usedCoco
    }
  },
  watch: {
    currentHomeId: {
      handler: function(id) {
        console.log('currentHomeId 改變了' + id)
        this.currentHomeIndex = this.resetAddressFromId(id)
        console.log('ruleForm validate:' + this.$refs[`ruleForm`].validate())
      }
    },
    paymentHtml: {
      handler: function(html) {
        if (html != '') {
          this.showHtml = true
          this.renewHtml(html)
        }
      }
    },
    userCoco: {
      immediate: true,
      handler: function() {
        this.cocoCash = this.userCoco
      }
    }
  },
  created() {
    // window.onpageshow = function(event) {
    //   if (event.persisted) {
    //     window.location = '/dashboard'
    //   }
    // };
  },
  async mounted() {
    this.postLog({
      code: LogEnums.INTO_PAY
    })
    if (!this.$route.params.form) {
      await this.$router.push('/')
    }
    await this.initData()
    await this.handleGetAddressesData()
    await this.getUserCoco()
    this.initDone = true
    this.shipValue = 'ship-value-a' //自動展開收件方式 - 宅配
    console.log('initDone')

    const items = this.handleGaCheckoutData()
    this.$gtm.trackEvent(checkout({ step: 2 }, items))
  }
}
</script>

<style lang="postcss" scoped>
.mb-2px {
  margin-bottom: 2px;
}

.radio-address .ant-radio {
  /*選擇門市 地址的radio樣式客製化*/
  vertical-align: top;
  margin-top: 5px;
}

/*地址select*/
.c-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  align-items: center;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAG0lEQVR42mNgwAfKy8v/48I4FeA0AacVDFQBAP9wJkE/KhUMAAAAAElFTkSuQmCC) no-repeat;
  background-position: calc(100% - 1rem);
  border-radius: 18px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  font-feature-settings: 'tnum';
  font-size: 14px;
  font-variant: tabular-nums;
  height: 36px;
  justify-content: center;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 4px;
  padding: 4px 11px;
  position: relative;
  transition: all 0.3s;
  width: 100%;
  box-sizing: border-box;
}
.c-select:active,
.c-select:focus {
  outline: none;
}

.c-radio-group {
  @apply md:text-xl;
  .ant-radio-wrapper {
    @apply flex;
    @apply items-center;
    @apply md:text-xl;
  }
}

.ant-modal-header {
  @apply md:pb-0;
  @apply border-none;
}

.ant-modal-title {
  @apply md:text-xl;
}

.ant-modal-footer {
  @apply border-none;
}

/* 備註 */
.textarea-basic .ant-input {
  @apply text-sm;
  width: 100%;
}

/*價格寬度對齊*/
.price-min-width {
  min-width: 100px;
}

.ant-checkbox + span {
    padding-right: 0;
    padding-left: 0;
}

.home-address {
  white-space: normal;
}
</style>
